import React, { memo, useEffect } from "react";
import { ReactComponent as WarningIcon } from "images/icons/error.svg";
import { WARNING_500 } from "components/colors";
import CustomNotification from "./styled";

export default memo(({ className = "" }) => {
  return (
    <CustomNotification
      className={className}
      customIcon={<WarningIcon fill={WARNING_500} />}
      title="NEW UPDATE!"
      desc={
        <>
          <p>
              Product Circle Acrylic Ornament is temporarily out of stock. Orders created before Dec 06 will still be printed and shipped to your end customers.
          </p>
          <p>
              Warning: Starting 01/01/2025, orders to Mexico require an RFC number (Registro Federal de Contribuyentes). Without it, orders may be held at customs or undelivered. You can enter that number in the Tax number field before proceeding with the order.
          </p>
        </>
      }
    />
  );
});
