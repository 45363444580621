import React from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { createStore, compose, applyMiddleware } from 'redux';
import createSagaMiddleware from 'redux-saga';
import { routerMiddleware } from 'connected-react-router';
import { createBrowserHistory } from 'history';
import App from './App';
import reducer from './reducer';
import saga from './saga';
import * as serviceWorker from './serviceWorker';
import 'antd/dist/antd.css';
import './sass/index.scss';
import 'react-resizable/css/styles.css';

import maintenanceImage from './assets/images/upgrade_system.gif';
const isMaintenanceMode = false;
const history = createBrowserHistory({ basename: '/' });

// Please make sure to call this method only once!

const sagaMiddleware = createSagaMiddleware();
const composeSetup =
  process.env.NODE_ENV !== 'production' &&
  typeof window === 'object' &&
  window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
    ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
    : compose;

const store = createStore(
  reducer(history),
  composeSetup(applyMiddleware(routerMiddleware(history), sagaMiddleware))
);
sagaMiddleware.run(saga);
const container = document.getElementById('root');
const root = createRoot(container);
root.render(
  <Provider store={store}>
      {isMaintenanceMode ? (
          <div style={{width:'100%','text-align':'center'}} className="maintenance-container">
              <img src={maintenanceImage} alt="Maintenance" />
          </div>
      ) : (
          <App history={history} />
      )}
  </Provider>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
